<template>
  <!-- 改了 -->
  <div class="main" :class="show ? 'h5main' : ''">
    <div class="htop">
      <mtop />
    </div>
    <!-- <div class="demo_top" id="top"></div> -->
    <div class="demo_box1" id="top">
      <div class="box1_top">
        <!-- <div class="img"></div> -->
        <div>
          <span @click="toPage('/')">网站首页 ></span>
          <span @click="toPage('/newsCenter', 0)" v-if="typeList[1] == 2"
            >公司新闻
          </span>
          <span @click="toPage('/newsCenter', 1)" v-if="typeList[1] == 3"
            >行业动态
          </span>
          <span @click="toPage('/newsCenter', 2)" v-if="typeList[1] == 4"
            >技术学堂
          </span>
          <span @click="toPage('/setParty')" v-if="typeList[0] == 5"
            >党建中心
          </span>
          <span>>{{ actInfo.name }}</span>
        </div>
      </div>
      <div class="box1_bottom">
        <div class="bottom_left">
          <div class="left_title">{{ actInfo.name }}</div>

          <div class="left_time">
            时间：{{ actInfo.createTime }} &nbsp&nbsp&nbsp&nbsp栏目：
            <span v-if="typeList[1] == 2">公司新闻</span>
            <span v-if="typeList[1] == 3">行业动态</span>
            <span v-if="typeList[1] == 4">技术学堂</span>
            <span v-if="typeList[1] == 6">支部动态</span>
          </div>

          <div class="left_con" v-html="actInfo.detail"></div>

          <div class="left_tip">
            <!-- 声明：如没特殊注明，文章均为善达信息原创，转载请注明 -->
            <span class="next">下一篇：</span
            ><span class="tips" @click="nextOpt">{{ nextName }}</span>
          </div>
        </div>
        <div class="bottom_right">
          <div class="right_item">
            <div class="item_title">相关内容</div>
            <div
              class="item_item"
              v-for="(item, index) in rightList"
              :key="index"
              @click="getMes(item.id)"
            >
              <div class="img">
                <img :src="item.coverImgUrl" alt="" />
              </div>
              <div class="item_con">{{ item.name }}</div>
              <div class="item_date">{{ item.createTime }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <mbottom />
  </div>
</template>

<script>
import { connectTron, walletOfOwner, tokenURI } from "@/api/Public";
import mtop from "../components/top.vue";
import mbottom from "../components/bottom.vue";
export default {
  data() {
    return {
      actInfo: {},
      rightkey: [],
      rightValue: [],
      rightList: [],
      id: null,
      list: [1, 2, 3, 4, 5, 6],
      active: "",
      show: false,
      name: "",
      formdata: {
        Page: 1,
        PageSize: "20",
      },
      menu: "",
      loading: false,
      finished: false,
      refreshing: false,
      showlist: false,
      mylist: {},
      basemenu: "",
      nextId: "",
      nextName: "",
      typeList: [],
    };
  },
  components: {
    mtop,
    mbottom,
  },
  mounted() {
    if (this._isMobile()) {
      this.show = true;
    } else {
      this.show = this.$store.getters.phone;
    }
  },
  watch: {
    "$store.getters.conid": function (val) {
      sessionStorage.setItem("conid", val);
      console.log(val);
    },
    "$store.getters.phone": function (val) {
      this.show = val;
    },
    deep: true,
    immediate: true,
  },
  created() {
    this.rightList = JSON.parse(localStorage.getItem("newsListdd"));
    console.log("tempList---->", this.rightList);
    // this.getMenu();
    if (this.$route.query.detail) {
      this.id = this.$route.query.detail;
      this.getInfo();
      console.log(this.id);
    } else {
      this.id = sessionStorage.getItem("conid");
      this.getInfo();
    }
    this.getNextList();
  },
  methods: {
    // 获取下一篇
    getNextList() {
      let indexx = 0;
      for (let i in this.rightList) {
        if (this.rightList[i].id == this.id) {
          indexx = Number(i);
          break;
        } else {
          indexx = Number(-1);
        }
      }
      if (indexx + 1 >= Number(this.rightList.length)) {
        this.nextId = "";
        this.nextName = "";
      } else {
        this.nextId = this.rightList[indexx + 1].id;
        this.nextName = this.rightList[indexx + 1].name;
      }
    },
    toPage(e, val) {
      // this.$router.replace({ path: e });
      this.$router.replace({
        path: e,
        query: {
          current: val,
        },
      });
    },
    nextOpt() {
      sessionStorage.setItem("conid", this.nextId);
      this.$router.push({
        name: "teamdetal",
        params: {
          detail: { id: this.nextId },
        },
      });
      location.reload();
    },
    getMes(val) {
      this.id = val;
      sessionStorage.setItem("conid", val);
      this.getInfo();
      this.getNextList();
      this.$nextTick(() => {
        document.getElementById("top").scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      });
    },
    async getInfo() {
      let res = await this.$http.get(this.$api.detailArticle, {
        id: this.id,
      });
      this.actInfo = res.data.data;
      // 使用DOMParser来解析HTML字符串
      const doc = new DOMParser().parseFromString(
        this.actInfo.detail,
        "text/html"
      );
      const images = doc.querySelectorAll("img");

      images.forEach((img) => {
        // 添加你想要的任何属性
        img.setAttribute('style', 'max-width: 808px');
      });
      this.actInfo.detail = doc.documentElement.innerHTML;
      this.typeList = this.actInfo.typeId.split(",");
      // let strss = this.actInfo.typeId.split(',')[1]
      // let res2 = await this.$http.post(this.$api.listArticle, {
      //   current: 1,
      //   size: 5,
      //   typeId: `1,${strss}`,
      //   status: '1'
      // });
      // this.rightList = res2.data.data.records
    },
    wallet() {
      var i = 0;
      var a = setInterval(() => {
        if (typeof window.ethereum !== "undefined") {
          connectTron().then(
            (info) => {
              clearInterval(a);
              if (info[0].indexOf("0x") == 0) {
                this.$store.commit("userWallet", info[0]);
              } else {
                this.$toast("Use the ETH address to log in to the game");
              }
            },
            (e) => {
              this.$toast(e.message);
            }
          );
        }
        if (i > 5) {
          clearInterval(a);
        }
        i++;
      }, 1000);
    },
    seach() {
      this.formdata = {
        Name: this.name,
        Page: 1,
        PageSize: "20",
      };
      this.menu = this.basemenu;
      this.$forceUpdate();
      this.finished = false;
      this.loading = true;
      this.onLoad();
    },
    tochange(e, j, i) {
      this.menu[i].list.forEach((el) => {
        if (j == el.name) {
          el.show = true;
        } else {
          el.show = false;
        }
      });
      this.$set(this.menu[i], "child", j);
      console.log(e, j);
      if (JSON.stringify(this.formdata).indexOf(e) != -1) {
        this.formdata[e] = j;
      } else {
        var a = { [e]: j };
        this.formdata = Object.assign(a, this.formdata);
      }
      this.onRefresh();
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    res() {
      this.formdata = {
        Page: 1,
        PageSize: 20,
      };
      this.name = "";
      // this.getMenu();
      this.onRefresh();
    },
    goopen(e) {
      let url =
        "https://opensea.io/assets/matic/0xb5e200d739bc792e273b2beb6dea9fd918ede79b/" +
        e;
      window.open(url);
    },
    onRefresh() {
      this.formdata.Page = 1;
      this.finished = false;
      this.loading = true;
      this.onLoad();
    },
    onLoad() {
      if (this.formdata.Page == 1) {
        this.list = [];
        this.refreshing = false;
      }
      this.$get({
        url: "cupid",
        data: this.formdata,
        callback: (res) => {
          this.$nextTick(function () {
            if (res.data) {
              let reg = new RegExp("ipfs://", "g");
              var list = res.data.items.map((el) => {
                el.url = el.image.replace(
                  reg,
                  "https://www.cupidlovemetaverse.com/ipfs/"
                );
                return el;
              });

              this.loading = false;
              if (this.formdata.Page > 1) {
                this.list = [...this.list, ...list];
              } else {
                this.list = list;
              }
              // console.log(this.list);

              if (res.data.items.length == 20) {
                this.formdata.Page++;
              } else {
                this.finished = true;
              }
            }
          });
        },
      });
    },
    getMenu() {
      this.$get({
        url: "menu",
        data: {},
        callback: (res) => {
          this.$nextTick(function () {
            if (res.data) {
              this.menu = res.data;
              this.basemenu = res.data;
            }
          });
        },
      });
    },
    tochild(e, s) {
      this.menu.forEach((el, i) => {
        if (e == i) {
          el.show = !s;
        } else {
          el.show = false;
        }
      });
      this.$forceUpdate();

      // this.$set(this.menu[e],"show",!s)
    },
    toshowList() {
      var address = this.$store.getters.userWallet;
      if (!address) {
        this.wallet();
        return;
      }
      walletOfOwner(this.$store.getters.userWallet).then((res) => {
        // console.log("res",res);
        this.basedata = res;
        if (res.length > 0) {
          this.showlist = true;
          res.forEach((el) => {
            this.getUrl(el);
          });
          // this.list.length=res.length
          // console.log(this.list);
        }
      });
    },
    closes() {
      this.showlist = false;
    },
    getUrl(e) {
      tokenURI(e).then((res) => {
        let regs = new RegExp("https://ipfs.io/", "g");
        var urls = res.replace(regs, "https://www.cupidlovemetaverse.com/");
        this.$axios({
          method: "GET",
          url: urls,
        }).then((response) => {
          // console.log(response.data);
          let reg = new RegExp("ipfs://", "g");
          var url = response.data.image.replace(
            reg,
            "https://www.cupidlovemetaverse.com/ipfs/"
          );
          this.mylist[e] = url;

          this.$forceUpdate();
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$t-mf: "Arial";

.el-icon-arrow-down:before {
  content: "";
}

.main {
  background: #ffffff;
  min-height: 100vh;
  max-width: 100%;
  min-width: 1200px;
  width: 100%;

  .htop {
    height: 64px;
  }

  .wtop {
    border-bottom: 1px solid #dfdfdf;
  }

  .demo_top {
    width: 100%;
    height: 151px;
    // background: #000000;
  }

  .demo_box1 {
    width: 1200px;
    margin: 87px auto auto auto;

    .box1_top {
      display: flex;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 14px;
      color: #8d8f94;
      line-height: 21px;
      text-align: left;
      font-style: normal;
      text-transform: none;
      cursor: pointer;

      align-items: center;

      .img {
        width: 24px;
        height: 24px;
        // background: url("../assets/indeximg/point.png");
        background-size: 100% 100%;
        margin-right: 12px;
      }

      div span:last-child {
        color: #3a89fe;
      }
    }

    .box1_bottom {
      width: 1200px;
      //   min-height: 100vh;
      padding: 62px 0 62px 0;
      //   border: 1px solid #f3f3f3;
      margin: 0 auto;
      display: flex;
      box-sizing: border-box;
      justify-content: space-between;

      // align-items: center;
      .bottom_left {
        width: 808px;
        // height: 1429px;
        // min-height: 360px;
        background: #ffffff;
        box-sizing: border-box;

        // overflow: auto;
        .left_title {
          font-weight: 600;
          font-size: 24px;
          color: #1e1e21;
          line-height: 30px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          margin-bottom: 18px;
        }

        .left_time {
          font-weight: 400;
          font-size: 16px;
          color: #8d8f94;
          line-height: 21px;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }

        .left_con {
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #000000;
          line-height: 28px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          margin: 62px 0px 70px 0;
          ::v-deep(*){
            max-width: 100% !important;
          }
        }

        .left_tip {
          text-align: right;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 16px;

          font-style: normal;
          text-transform: none;

          .next {
            color: #000000;
          }

          .tips {
            color: #3a89fe;
            cursor: pointer;
          }
        }
      }

      .bottom_right {
        width: 287px;
        // height: 1429px;
        // min-height: 360px;
        background: #ffffff;
        box-sizing: border-box;

        .right_item {
          width: 287px;
          //   max-height: 284px;

          .item_title {
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 20px;
            color: #1e1e21;
            line-height: 30px;
            text-align: left;
            font-style: normal;
            text-transform: none;
            margin-bottom: 20px;
          }

          .item_item {
            margin-bottom: 36px;

            .img {
              width: 287px;
              height: 124px;
              overflow: hidden;
              border-radius: 8px 8px 8px 8px;
              margin-bottom: 10px;

              img {
                width: 100%;
                height: 100%;
                border-radius: 8px 8px 8px 8px;
                transition: 0.35s;
                object-fit:cover;
              }
            }

            img:hover {
              transform: scale(1.1);
            }

            .item_con {
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              font-size: 14px;
              color: #1e1e21;
              line-height: 30px;
              text-align: left;
              font-style: normal;
              text-transform: none;
              cursor: pointer;
              margin-bottom: 5px;
            }

            .item_con:hover {
              color: #3a89fe;
            }

            .item_date {
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              font-size: 12px;
              color: #8d8f94;
              line-height: 21px;
              text-align: left;
              font-style: normal;
              text-transform: none;
            }
          }
        }
      }
    }
  }
}

.h5main {
  width: 100%;
  min-width: 100%;

  .demo_top {
    height: 125px;
  }

  .demo_box1 {
    width: 100%;

    .box1_top {
      line-height: 88px;
      font-size: 26px;
      padding-left: 10px;
    }

    .box1_bottom {
      width: 100%;
      padding: 10px;
      height: auto;
      flex-direction: column;
      justify-content: flex-start;

      .bottom_left {
        width: auto;

        .left_title {
          font-size: 32px;
        }

        .left_time {
          font-size: 24px;
        }

        .left_con {
          font-size: 26px;
        }

        .left_tip {
          font-size: 24px;
        }
      }

      .bottom_right {
        width: auto;
        margin-top: 60px;

        .right_item {
          width: auto;
          max-height: 100%;

          .item_title {
            font-size: 26px;
            margin: 20px 0;
          }

          .item_item {
            .item_con {
              line-height: 66px;
              font-size: 26px;
            }

            .item_date {
              font-size: 24px;
            }
          }
        }
      }
    }
  }
}
</style>
